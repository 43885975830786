import styles from './country-list.scss';
import {getCountryService, ICountryService} from "../../services/country/country-service";
import {Country} from "../../services/country/country";


export class CountryList extends HTMLElement {
  private cs: ICountryService;
  private countries: Country[];
  private currentCountryCode: string;

  static get observedAttributes() {
    return ['currentcountry']
  }

  constructor() {
    super();
    this.cs = getCountryService();

    this.countryClicked = this.countryClicked.bind(this);
  }

  countryClicked(c: Country) {
    this.emitCountrySelected(c)
  }

  handleKeyPress(e: KeyboardEvent, c: Country) {
    const key = e.code.toLowerCase();
    if (key === "enter" || e.code.toLowerCase() === "space") {
      e.preventDefault();
      this.countryClicked(c);
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'currentcountry' && newValue !== this.currentCountryCode) {
      this.currentCountryCode = newValue;
      if (this.countries) {
        this.render();
      }
    }
  }

  render() {
    this.innerHTML = `
    <style>${styles}</style>
    <div tabindex="0" aria-label="country list" class="country-list" id="country-list-scroll-container">
      ${this.countries.map(c => this.renderCountry(c)).join(' ')}
    </div>
    `
    this.querySelectorAll('.country-list .country').forEach((ce, i) => ce.addEventListener('click', () => this.countryClicked(this.countries[i])))
    this.querySelectorAll('.country-list .country').forEach((ce, i) => ce.addEventListener('keydown', (e: KeyboardEvent) => this.handleKeyPress(e, this.countries[i])))
  }

  renderCountry(country: Country) {
    if (this.currentCountryCode && this.currentCountryCode.toLowerCase() === country.iso_3166.toLowerCase()) {
      return `
      <div class="country active" id="active-country" tabindex="0">
        ${country.name} | ${country.localized_name} <img src="/img/icons/check.svg" alt="Checkmark" />
      </div>
    `
    }

    return `
      <div class="country" tabindex="0">
        ${country.name} | ${country.localized_name}
      </div>
    `
  }

  emitCountrySelected(country: Country) {
    window.dispatchEvent(new CustomEvent("ny-country-selected", {
      detail: {
        country: country
      }
    }));
  }

  emitCountriesLoaded(countries: Country[]) {
    window.dispatchEvent(new CustomEvent("ny-countries-loaded", {
      detail: {
        countries: countries
      }
    }));
  }

  async connectedCallback() {
    this.currentCountryCode = this.getAttribute('currentCountry') || this.currentCountryCode;
    this.countries = (await this.cs.getCountries()).sort((a, b) => a.name.localeCompare(b.name));
    this.emitCountriesLoaded(this.countries);
    this.render();
  }
}

window.customElements.define('country-list', CountryList);
